<template>
    <div class="unOuter mainOuter">
        <div class="container_un">
            <div class="subWhite animated fadeInLeft ani_delay05">
                <div class="sub_bg_bot"></div>
                <div class="subTitBox">
                    <p id="t_page_title" class="subTitle">{{$t('deposit.title')}}</p>
                </div>
                <div style="margin-top: 150px;">

                        <vue-qr :text="address" :size="300" class='mt-4 mb-4 mx-auto d-block'></vue-qr>
                    <h2 style="text-align: center; color: #fff;">{{$t('deposit.address')}}</h2>
                    <p style="text-align: center; color: #fff; font-size: 20px;">
                        {{address}}</p>
                </div>
                <div class="bWrap">
                    <button name="submitBtn" class="bOk card_bg5"  v-clipboard:copy="address" v-clipboard:success="onCopy">{{$t('deposit.copy')}}</button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import VueQr from 'vue-qr'
const CryptoJS = require("crypto-js");
export default {
    components: {VueQr},
    data(){
        return{
            address:''
        }
    },
    mounted(){
        this.GetWalletInfo()
    },
    methods:{
        GetWalletInfo(){
            
            this.$axios.post('/member/coin/GetWalletInfo', {}).then(
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            this.address = body.wallet.address;
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/sign/login";
                                }
                            )
                        }
                    }
                }
            )
        },
        onCopy(e){
            this.$alert(`${this.$i18n.t('deposit.copyComplete')} ${e.text}`);
        },
    }
}
</script>

<style>

</style>